import springApi from '../../services/springApi'

export const login = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/login', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const logout = () => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/logout')
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)