import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import EmployeeRewardsList from './EmployeeRewardsList'

export default function EmployeeRewards() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/employee_rewards" component={EmployeeRewardsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}