import springApi, { getDomain } from '../../services/springApi'

export const getEmployeeRewards = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/employee_rewards', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEmployeeReward = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/employee_rewards/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEmployeeReward = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/employee_rewards', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEmployeeReward = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/employee_rewards/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEmployeeReward = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/employee_rewards/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEmployeeRewards = async (name, token) => (
	fetch( getDomain()+`/employee_rewards?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(employee_reward => ({
				label: employee_reward.fullname,
				value: employee_reward.id,
			})),
		)
)