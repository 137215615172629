import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ProductRatesList from './ProductRatesList'

export default function ProductRates() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/product_rates" component={ProductRatesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}