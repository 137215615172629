import springApi, { getDomain } from '../../services/springApi'

export const getReferralOrders = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/referral_orders', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReferralOrder = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/referral_orders/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReferralOrder = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/referral_orders', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReferralOrder = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/referral_orders/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReferralOrder = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/referral_orders/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReferralOrders = async (name, token) => (
	fetch( getDomain()+`/referral_orders?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(referral_order => ({
				label: referral_order.fullname,
				value: referral_order.id,
			})),
		)
)