import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CouponsList from './CouponsList'

export default function Coupons() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/coupons" component={CouponsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}