import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import OrderInvoicesList from './OrderInvoicesList'

export default function OrderInvoices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/order_invoices/coupons" render={() => <OrderInvoicesList scope="coupons" />} />
				<Route exact path="/order_invoices/referrals" render={() => <OrderInvoicesList scope="referrals" />} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}