import springApi, { getDomain } from '../../services/springApi'

export const getEmployees = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/employees', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEmployee = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/employees/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEmployee = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/employees', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEmployee = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/employees/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEmployee = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/employees/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEmployees = async (name, token) => (
	fetch( getDomain()+`/employees?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(employee => ({
				label: employee.fullname,
				value: employee.id,
			})),
		)
)